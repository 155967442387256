import React, { useEffect, useState } from "react";
import videoBg from '../img/bg.mp4';
import posterBg from '../img/bg.png';
import { AiOutlineHome } from "react-icons/ai";
import SelectedInfo from "./selectinfopage";
import { useNavigate } from "react-router-dom";
import CustomCursor from "./cursorapp";


const AboutMe = () => {
    const [bgPhotoClass, setBgPhotoClass] = useState("bgphoto1in");

    const navigate = useNavigate();

        function redirectHome() {
                navigate("/");
        }


    useEffect(() => {
        const toggleIn = () => {
            setTimeout(() => {
                setBgPhotoClass((prevClass) => {
                    if (prevClass === "bgphoto1in") {
                        return "bgphoto1out";
                    } if (prevClass === "bgphoto2in") {
                        return "bgphoto2out";
                    }
                    return prevClass;
                });
                toggleOut();
            }, 10000);
        };

        const toggleOut = () => {
            setTimeout(() => {
                setBgPhotoClass((prevClass) => {
                    if (prevClass === "bgphoto1out") {
                        return "bgphoto2in";
                    } if (prevClass === "bgphoto2out") {
                        return "bgphoto1in";
                    }
                    return prevClass;
                });
                toggleIn();
            }, 1000);
        };

        toggleIn();
    }, []);




    return (
        <section>
            <CustomCursor/>
            <div> <video src={videoBg} autoPlay loop muted poster={posterBg} /> </div>
            <div className={bgPhotoClass}><span>t</span></div>
            <div className="section-title">
                <h1>Sobre mim</h1>
            </div>
            <div className="container-section">


                <SelectedInfo />


            </div>
            <div className="back-to-home-bl">
                <span>
                    <AiOutlineHome color="#892cdc" size={25} onClick={redirectHome}/>
                </span>
            </div>
        </section>
    )
}

export default AboutMe