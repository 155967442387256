import React from "react";
import videoBg from '../img/bg.mp4';
import posterBg from '../img/bg.png';
import { AiOutlineHome } from "react-icons/ai";
import annonymousRoc from '../img/anonymousrecommend.png';
import { useNavigate } from "react-router-dom";
import CustomCursor from "./cursorapp";
import marinaRecommend from '../img/marinarecommend.jpg';

const Recommendations = () => {
    const navigate = useNavigate();

        function redirectHome() {
                navigate("/");
        }


    return (
        <section>
            <CustomCursor/>
            <div> <video src={videoBg} autoPlay loop muted poster={posterBg} /> </div>

            <div className="section-title">
                <h1>Recomendações</h1>
            </div>

            <div className="container-recommends">
                <div className="container-recommendations">

                    <div className="recommendation1">

                        <div className="recommend">
                            <div>
                                <img src={marinaRecommend} width={100} />
                            </div>
                            <div>
                                <h2>Marina Rodrigues Pires Feitosa</h2>
                                <h3>Administradora</h3>
                                <h4>"Quem conhece pode assegurar que realmente é uma das pessoas mais comprometidas, honestas e superinteligentes que já conheci. Possui um grande conhecimento e consegue sempre se destacar pela capacidade em solucionar problemas, ajudar sua equipe e ser resiliente."</h4>
                                <h5>Recomendado em: 16 de Dezembro de 2023</h5>
                            </div>
                        </div>

                    </div>

                    <div className="recommendation2">

                    </div>

                </div>
            </div>

           <a href="https://ac6oxj9qarv.typeform.com/to/CzFBjNSL" className="recomende-me" target="_blank" rel="noopener noreferrer">
                Me Recomende!
            </a>
            

            <div className="back-to-home">
                <span>
                    <AiOutlineHome color="#892cdc" size={25} onClick={redirectHome}/>
                </span>
            </div>
        </section>
    )
}

export default Recommendations;