import React, { useState } from "react";
import videoBg from '../img/bg.mp4';
import posterBg from '../img/bg.png';
import jstph from '../img/javascripttrophy.png';
import htmltph from '../img/htmltrophy.png';
import pytph from '../img/pythonptrophy.png';
import typetph from '../img/typetrophy.png';
import vbatph from '../img/vbatrophy.png';
import csstph from '../img/csstrophy.png';
import rcttph from '../img/reacttrophy.png';
import rctntvph from '../img/nativetrophy.png';
import nodetph from '../img/nodetrophy.png';
import angtph from '../img/angulartrophy.png';
import vuetph from '../img/vuetrophy.png';
import mdbtph from '../img/mongodbtrophy.png';
import awstph from '../img/awstrophy.png';
import fgmatph from '../img/figmatrophy.png';
import pstph from '../img/photoshoptrophy.png';
import aitph from '../img/illustratortrophy.png';
import cvatph from '../img/canvatrophy.png';
import aetph from '../img/aftereffectstrophy.png';
import cdtph from '../img/coreldrawtrophy.png';
import { AiOutlineHome } from "react-icons/ai";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CustomCursor from "./cursorapp";

const MinhasCompetencias = () => {
        const [infoTrophy, setInfoTrophy] = useState(null);
        const [countProjects, setCountProjects] = useState(0);
        const [intervalRef, setIntervalRef] = useState(null);
        const [selectedTrophy, setSelectedTrophy] = useState(null);
        const [countYears, setCountYears] = useState(0);
        const [intervalRef2, setIntervalRef2] = useState(null);

        const navigate = useNavigate();

        function redirectHome() {
                navigate("/");
        }

        const openCardInfo = (trophy) => {
                setSelectedTrophy(trophy);
                setInfoTrophy(true);
                const projectsForTrophy = getProjectsForTrophy(trophy);
                const yearsExpForTrophy = getYearsExpForTrophy(trophy);

                numberOfProjectsAnimate(projectsForTrophy);
                numberYearsOfExperience(yearsExpForTrophy);
        };

        const closeCardInfo = () => {
                setInfoTrophy(false);
                setCountProjects(0);
                setCountYears(0);
                clearInterval(intervalRef);
                clearInterval(intervalRef2);
        }

        const getProjectsForTrophy = (trophy) => {
                switch (trophy) {
                        case "javascript":
                                return 5;
                        case "html":
                                return 7;
                        case "python":
                                return 1;
                        case "typescript":
                                return 2;
                        case "vba":
                                return 2;
                        case "css":
                                return 7;
                        case "native":
                                return 1;
                        case "react":
                                return 4;
                        case "node":
                                return 4;
                        case "angular":
                                return 1;
                        case "vue":
                                return 1;
                        case "mongo":
                                return 1;
                        case "aws":
                                return 1;
                        case "figma":
                                return 10;
                        case "photoshop":
                                return 5;
                        case "illustrator":
                                return 20;
                        case "canva":
                                return 25;
                        case "after":
                                return 2;
                        case "corel":
                                return 10;
                        default:
                                return 0;
                }
        };

        const getYearsExpForTrophy = (trophy) => {
                switch (trophy) {
                        case "javascript":
                                return 2;
                        case "html":
                                return 3;
                        case "python":
                                return 1;
                        case "typescript":
                                return 1;
                        case "vba":
                                return 2;
                        case "css":
                                return 3;
                        case "native":
                                return 1;
                        case "react":
                                return 2;
                        case "node":
                                return 2;
                        case "angular":
                                return 1;
                        case "vue":
                                return 1;
                        case "mongo":
                                return 1;
                        case "aws":
                                return 1;
                        case "figma":
                                return 1;
                        case "photoshop":
                                return 1;
                        case "illustrator":
                                return 2;
                        case "canva":
                                return 5;
                        case "after":
                                return 1;
                        case "corel":
                                return 5;
                        default:
                                return 0;
                }
        };

        const numberOfProjectsAnimate = (projectsForTrophy) => {
                const interval = setInterval(() => {

                        setCountProjects((prevCount) => {
                                if (prevCount < projectsForTrophy) {
                                        return prevCount + 1;
                                } else {

                                        clearInterval(interval);
                                        return projectsForTrophy;
                                }
                        });
                }, 500);

                setIntervalRef(interval);
        };

        const numberYearsOfExperience = (yearsExpForTrophy) => {
                const interval = setInterval(() => {

                        setCountYears((prevCount) => {
                                if (prevCount < yearsExpForTrophy) {
                                        return prevCount + 1;
                                } else {

                                        clearInterval(interval);
                                        return yearsExpForTrophy;
                                }
                        });
                }, 500);

                setIntervalRef2(interval);
        };

        const trophyImg = () => {
                if (selectedTrophy === null) { return null }
                if (selectedTrophy === "javascript") { return jstph }
                if (selectedTrophy === "html") { return htmltph }
                if (selectedTrophy === "python") { return pytph }
                if (selectedTrophy === "typescript") { return typetph }
                if (selectedTrophy === "vba") { return vbatph }
                if (selectedTrophy === "css") { return csstph }
                if (selectedTrophy === "native") { return rctntvph }
                if (selectedTrophy === "react") { return rcttph }
                if (selectedTrophy === "node") { return nodetph }
                if (selectedTrophy === "angular") { return angtph }
                if (selectedTrophy === "vue") { return vuetph }
                if (selectedTrophy === "mongo") { return mdbtph }
                if (selectedTrophy === "aws") { return awstph }
                if (selectedTrophy === "figma") { return fgmatph }
                if (selectedTrophy === "photoshop") { return pstph }
                if (selectedTrophy === "illustrator") { return aitph }
                if (selectedTrophy === "canva") { return cvatph }
                if (selectedTrophy === "after") { return aetph }
                if (selectedTrophy === "corel") { return cdtph }
        }

        const trophyName = () => {
                if (selectedTrophy === null) { return null }
                if (selectedTrophy === "javascript") { return "JavaScript" }
                if (selectedTrophy === "html") { return "HTML" }
                if (selectedTrophy === "python") { return "Python" }
                if (selectedTrophy === "typescript") { return "TypeScript" }
                if (selectedTrophy === "vba") { return "Excel VBA" }
                if (selectedTrophy === "css") { return "CSS" }
                if (selectedTrophy === "native") { return "React Native" }
                if (selectedTrophy === "react") { return "React" }
                if (selectedTrophy === "node") { return "Node Js" }
                if (selectedTrophy === "angular") { return "Angular" }
                if (selectedTrophy === "vue") { return "Vue" }
                if (selectedTrophy === "mongo") { return "Mongo DB" }
                if (selectedTrophy === "aws") { return "AWS Cloud" }
                if (selectedTrophy === "figma") { return "Figma" }
                if (selectedTrophy === "photoshop") { return "Photoshop" }
                if (selectedTrophy === "illustrator") { return "Illustrator" }
                if (selectedTrophy === "canva") { return "Canva" }
                if (selectedTrophy === "after") { return "After Effects" }
                if (selectedTrophy === "corel") { return "Corel Draw" }
        }

        const trophyDescription = () => {
                if (selectedTrophy === null) { return null }
                if (selectedTrophy === "javascript") { return "Javascript foi a minha primeira paixão no universo da programação. Possuo conhecimento técnico e prático o suficiente para abarcar grandes projetos sozinho ou em grupo de maneira escalável." }
                if (selectedTrophy === "html") { return "Programar e desenvolver com HTML é uma diversão indescritível, utilizo sempre que possível em meus projetos. Tenho pleno conhecimento dos benefícios que o HTML pode oferecer." }
                if (selectedTrophy === "python") { return "O Python é uma linguagem que logo me chamou atenção, sua abordagem e sintaxe simplista e eficaz me atraiu para o universo de análise de dados e desenvolvimento de dashboards. Não tenho tanto conhecimento técnico e prático, mas continuo estudando e evoluindo para me desenvolver nesta linguagem." }
                if (selectedTrophy === "typescript") { return "Tenho prévio conhecimento e estou em estudo contínuo me desenvolvendo no Typescript." }
                if (selectedTrophy === "vba") { return "Tenho um grande apreço com planilhas e habilidades que me possibilitam fazer coisas grandiosas por meio do Excel. Obtive algum conhecimento de VBA e tornei minhas planilhas como um verdadeiro software." }
                if (selectedTrophy === "css") { return "O velho CSS genérico ... Veja, todo o conteúdo nesta página tem um monte de CSS na UI." }
                if (selectedTrophy === "native") { return "Tenho um conhecimento razoável, consigo desenvolver aplicativos demasiadamente complexos e continuo em constante aprendizado." }
                if (selectedTrophy === "react") { return "Atualmente, trabalho no mundo react, tenho um bom conhecimento, mas ainda tenho conhecimento para abarcar." }
                if (selectedTrophy === "node") { return "O Node é ... o node." }
                if (selectedTrophy === "angular") { return "Tenho apreço por este framework, cogitado, específico e decisivo. Estou estudando e me dedicando." }
                if (selectedTrophy === "vue") { return "Tenho apreço por este framework, cogitado, específico e decisivo. Estou estudando e me dedicando." }
                if (selectedTrophy === "mongo") { return "Este é sem dúvida uma das minhas opções de banco de dados noSQL favoritas." }
                if (selectedTrophy === "aws") { return "O trauma do Aws Cloud não me constrange mais." }
                if (selectedTrophy === "figma") { return "Ótima habilidade com design no geral, plugins ..." }
                if (selectedTrophy === "photoshop") { return "Pouco conhecimento, faço o mínimo, porém o necessário." }
                if (selectedTrophy === "illustrator") { return "Bom conhecimento, trabalho com vetores, curvas, logos e design em geral." }
                if (selectedTrophy === "canva") { return "Para designs e projetos rápidos e não complexos." }
                if (selectedTrophy === "after") { return "Conhecimento básico e faço somente o necessário." }
                if (selectedTrophy === "corel") { return "Não utilizo mais, mas tenho um bom conhecimento." }
        }

        const starRating = () => {
                if (selectedTrophy === null) { return null }
                if (selectedTrophy === "javascript") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "html") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "python") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "typescript") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "vba") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "css") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "native") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "react") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "node") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "angular") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "vue") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "mongo") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "aws") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "figma") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "photoshop") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "illustrator") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "canva") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "after") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "corel") {
                        return (
                                <div className="rating">
                                        <span>Nível de Conhecimento</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
        }

        return (
                <section className="trophyBg">
                        <CustomCursor />
                        <div> <video src={videoBg} autoPlay loop muted poster={posterBg} /> </div>
                        <div className="galery-trophy">
                                <div className="section-trophies">
                                        <div className="trophies1">
                                                <img src={jstph} width={80} onClick={() => openCardInfo("javascript")} />
                                                <img src={htmltph} width={80} onClick={() => openCardInfo("html")} />
                                                <img src={pytph} width={80} onClick={() => openCardInfo("python")} />
                                                <img src={typetph} width={80} onClick={() => openCardInfo("typescript")} />
                                                <img src={vbatph} width={80} onClick={() => openCardInfo("vba")} />
                                                <img src={csstph} width={80} onClick={() => openCardInfo("css")} />
                                        </div>

                                        <div className="text-divider">
                                                <h2 className="description">Linguagens</h2>
                                        </div>
                                </div>

                                <span></span>

                                <div className="section-trophies">
                                        <div className="trophies1">
                                                <img src={rctntvph} width={80} onClick={() => openCardInfo("native")} />
                                                <img src={rcttph} width={80} onClick={() => openCardInfo("react")} />
                                                <img src={nodetph} width={80} onClick={() => openCardInfo("node")} />
                                                <img src={angtph} width={80} onClick={() => openCardInfo("angular")} />
                                                <img src={vuetph} width={80} onClick={() => openCardInfo("vue")} />
                                        </div>

                                        <div className="text-divider">
                                                <h2 className="description">Frameworks</h2>
                                        </div>
                                </div>

                                <span></span>

                                <div className="section-trophies">
                                        <div className="trophies1">
                                                <img src={mdbtph} width={80} onClick={() => openCardInfo("mongo")} />
                                                <img src={awstph} width={80} onClick={() => openCardInfo("aws")} />
                                        </div>

                                        <div className="text-divider">
                                                <h2 className="description">Bancos de Dados</h2>
                                        </div>
                                </div>

                                <span></span>

                                <div className="section-trophies">
                                        <div className="trophies1">
                                                <img src={fgmatph} width={80} onClick={() => openCardInfo("figma")} />
                                                <img src={pstph} width={80} onClick={() => openCardInfo("photoshop")} />
                                                <img src={aitph} width={80} onClick={() => openCardInfo("illustrator")} />
                                                <img src={cvatph} width={80} onClick={() => openCardInfo("canva")} />
                                                <img src={aetph} width={80} onClick={() => openCardInfo("after")} />
                                                <img src={cdtph} width={80} onClick={() => openCardInfo("corel")} />
                                        </div>

                                        <div className="text-divider">
                                                <h2 className="description">Design Tools</h2>
                                        </div>
                                </div>
                        </div>

                        <div className="back-to-home">
                                <span>
                                        <AiOutlineHome color="#892cdc" size={25} onClick={redirectHome} />
                                </span>
                        </div>


                        {infoTrophy ? (

                                <div className="description-container">
                                        <div className="card-container">

                                                <div className="card-trophy">
                                                        <img src={trophyImg()} width={280} />
                                                </div>

                                                <div className="card-trophy-desc">
                                                        <h1>{trophyName()}</h1>
                                                        {starRating()}
                                                        <div className="counters">
                                                                <div>
                                                                        <h3>Projetos</h3>
                                                                        <h2>{countProjects}+</h2>
                                                                </div>
                                                                <div>
                                                                        <h3>Anos de Experiência</h3>
                                                                        <h2>{countYears}+</h2>
                                                                </div>
                                                        </div>
                                                        <div className="description-card">
                                                                <p>{trophyDescription()}</p>
                                                        </div>
                                                </div>

                                        </div>

                                        <span className="close-card" onClick={closeCardInfo}><IoClose color="black" size={17} /></span>

                                </div>

                        ) : (
                                <></>

                        )}

                </section>
        );

}

export default MinhasCompetencias;