import React from "react";
import { useNavigate } from "react-router-dom";
import LikeButtonEN from '../components/likebutton';

const MenuEN = () => {
  const navigate = useNavigate();

  function redirectSkills() {
    navigate("/enskills");
  }

  function redirectRecommendations() {
    navigate("/enrecommendations");
  }

  function redirectProjects() {
    navigate("/enprojects");
  }

  function redirectContactme() {
    navigate("/encontactme");
  }

  function redirectAboutme() {
    navigate("/enaboutme");
  }

  return (

    <div className="introcing">
      <LikeButtonEN/>
      <div className="menuShow open">
        <ul>
          <li onClick={redirectRecommendations}>Recommendations</li>
          <li onClick={redirectProjects}>My Projects</li>
          <li onClick={redirectContactme}>Contact me</li>
          <li onClick={redirectAboutme}>About me</li>
          <li onClick={redirectSkills}>My Skills</li>
        </ul>
      </div>
    </div>

  );
}

export default MenuEN;