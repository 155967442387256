import React from 'react';  
import Routes from './Routes';
import { useMediaQuery } from 'react-responsive';
import LandscapingMode from './components/landscapingmode';

const App = () => {
    const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
  
    return (
      <div>
        {isLandscape ? (
          <Routes />
        ) : (
          <LandscapingMode/>
        )}
      </div>
    );
  };
  
  export default App;