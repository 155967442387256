import React, { useEffect, useRef } from "react";

const TypeWriterCompEN = () => {
    const typo1Ref = useRef(null);
    const typo2Ref = useRef(null);
    const typo3Ref = useRef(null);

    const TypeWriterEffect = () => {
        useEffect(() => {
            const typo1 = typo1Ref.current;
            const texto = "HI, I AM";
            let i = 0;

            function typeWriter() {
                if (i < texto.length) {
                    typo1.innerHTML = texto.slice(0, i) + ' |';
                    i++;
                    setTimeout(typeWriter, 150);
                } else {
                    typo1.innerHTML = texto;
                    setTimeout(() => {
                        i = 0;
                        setTimeout(typeWriter, 6000);
                    }, 6000);
                }
            }
            typeWriter();
        }, []);

        return (
            <h2 ref={typo1Ref}></h2>
        );
    }

    const TypeWriterEffect2 = () => {
        useEffect(() => {
            const typo2 = typo2Ref.current;
            const texto = "SILAS PIRES";
            let i = 0;

            function typeWriter() {
                if (i < texto.length) {
                    typo2.innerHTML = texto.slice(0, i) + ' |';
                    i++;
                    setTimeout(typeWriter, 150);
                } else {
                    typo2.innerHTML = texto;
                    setTimeout(() => {
                        i = 0;
                        setTimeout(typeWriter, 6000);
                    }, 6000);
                }
            }

            setTimeout(() => {
                typeWriter();
            }, 2000);

        }, []);

        return (
            <h1 ref={typo2Ref}></h1>
        );
    }

    const TypeWriterEffect3 = () => {
        useEffect(() => {
            const typo3 = typo3Ref.current;
            const texto = "I'm a front end web/mobile developer, graphic designer & UX/UI designer";
            let i = 0;

            function typeWriter() {
                if (i < texto.length) {
                    typo3.innerHTML = texto.slice(0, i) + ' |';
                    i++;
                    setTimeout(typeWriter, 40);
                } else {
                    typo3.innerHTML = texto;
                }
            }

            setTimeout(() => {
                typeWriter();
            }, 4200);

        }, []);

        return (
            <p ref={typo3Ref}></p>
        );
    }


    return (
        <>
            <div className="intro">
                {TypeWriterEffect()}
                {TypeWriterEffect2()}
            </div>
            {TypeWriterEffect3()}
        </>
    );
}

export default TypeWriterCompEN;
