import React from "react";
import videoBg from '../img/bg.mp4';
import posterBg from '../img/bg.png';
import { AiOutlineHome } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import CustomCursorEN from "./cursorapp";

const ThanksScreenEN = () => {
    const navigate = useNavigate();

    function redirectHome() {
        navigate("/en");
    }


    return (
        <section>
            <CustomCursorEN/>
            <div> <video src={videoBg} autoPlay loop muted poster={posterBg} /> </div>

            <div className="section-msg">
                <h1>Your message has been sent successfully!</h1>
                <h2>I will respond as soon as possible.</h2>
            </div>

            <div className="back-to-home-tr">
                <span>
                    <AiOutlineHome color="#892cdc" size={25} onClick={redirectHome} />
                </span>
            </div>
        </section>
    )
}

export default ThanksScreenEN;