import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import MinhasCompetencias from './pages/minhascompetencias';
import Recommendations from "./pages/recommendations";
import ContactMe from "./pages/contactme";
import AboutMe from "./pages/aboutme";
import MyProjects from "./pages/myprojects";
import ThanksScreen from "./pages/thanks";
// English Pages //
import HomeEN from './pagesen/home';
import MinhasCompetenciasEN from './pagesen/minhascompetencias';
import RecommendationsEN from "./pagesen/recommendations";
import ContactMeEN from "./pagesen/contactme";
import AboutMeEN from "./pagesen/aboutme";
import MyProjectsEN from "./pagesen/myprojects";
import ThanksScreenEN from "./pagesen/thanks";


export default () => {

    return (

        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/skills" element={<MinhasCompetencias />} />
                <Route path="/recommendations" element={<Recommendations />} />
                <Route path="/contactme" element={<ContactMe />} />
                <Route path="/aboutme" element={<AboutMe />} />
                <Route path="/projects" element={<MyProjects />} />
                <Route path="/sent" element={<ThanksScreen />} />
                <Route path="/en" element={<HomeEN />} />
                <Route path="/enskills" element={<MinhasCompetenciasEN />} />
                <Route path="/enrecommendations" element={<RecommendationsEN />} />
                <Route path="/encontactme" element={<ContactMeEN />} />
                <Route path="/enaboutme" element={<AboutMeEN />} />
                <Route path="/enprojects" element={<MyProjectsEN />} />
                <Route path="/ensent" element={<ThanksScreenEN />} />
            </Routes>
        </BrowserRouter>

    );
}