import React from "react";
import videoBg from '../img/bg.mp4';
import posterBg from '../img/bg.png';
import { AiOutlineHome } from "react-icons/ai";
import annonymousRoc from '../img/anonymousrecommend.png';
import { useNavigate } from "react-router-dom";
import CustomCursorEN from "./cursorapp";
import marinaRecommend from '../img/marinarecommend.jpg';

const RecommendationsEN = () => {
    const navigate = useNavigate();

        function redirectHome() {
                navigate("/en");
        }

    return (
        <section>
            <CustomCursorEN/>
            <div> <video src={videoBg} autoPlay loop muted poster={posterBg} /> </div>

            <div className="section-title">
                <h1>Recommendations</h1>
            </div>

            <div className="container-recommends">
                <div className="container-recommendations">

                    <div className="recommendation1">

                        <div className="recommend">
                            <div>
                                <img src={marinaRecommend} width={100} />
                            </div>
                            <div>
                                <h2>Marina Rodrigues Pires Feitosa</h2>
                                <h3>Administrator</h3>
                                <h4>"Anyone who knows him can assure you that he really is one of the most committed, honest and super intelligent people I have ever met. He has great knowledge and always stands out for his ability to solve problems, help his team and be resilient."</h4>
                                <h5>Recommended on: December 16, 2023</h5>
                            </div>
                        </div>

                    </div>

                    <div className="recommendation2">

                    </div>

                </div>
            </div>

           <a href="https://ac6oxj9qarv.typeform.com/to/CzFBjNSL" className="recomende-me" target="_blank" rel="noopener noreferrer">
           Recommend me!
            </a>
            

            <div className="back-to-home">
                <span>
                    <AiOutlineHome color="#892cdc" size={25} onClick={redirectHome}/>
                </span>
            </div>
        </section>
    )
}

export default RecommendationsEN;