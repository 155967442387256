import React from "react";
import { useNavigate } from "react-router-dom";
import LikeButton from '../components/likebutton';

const Menu = () => {
  const navigate = useNavigate();

  function redirectSkills() {
    navigate("/skills");
  }

  function redirectRecommendations() {
    navigate("/recommendations");
  }

  function redirectProjects() {
    navigate("/projects");
  }

  function redirectContactme() {
    navigate("/contactme");
  }

  function redirectAboutme() {
    navigate("/aboutme");
  }

  return (

    <div className="introcing">
      <LikeButton/>
      <div className="menuShow open">
        <ul>
          <li onClick={redirectSkills}>Minhas Competências</li>
          <li onClick={redirectRecommendations}>Recomendações</li>
          <li onClick={redirectProjects}>Meus Projetos</li>
          <li onClick={redirectContactme}>Fale Comigo</li>
          <li onClick={redirectAboutme}>Sobre Mim</li>
        </ul>
      </div>
    </div>

  );
}

export default Menu;