import React, { useState } from "react";
import videoBg from '../img/bg.mp4';
import posterBg from '../img/bg.png';
import { AiOutlineHome } from "react-icons/ai";
import imgPrj1 from '../img/invoices_screen.png';
import imgPrj2 from '../img/landingia_screen.png';
import imgPrj3 from '../img/marriage_screen.png';
import imgPrj4 from '../img/imc_screen.png';
import imgPrj5 from '../img/note_screen.png';
import imgPrj6 from '../img/g10_screen.png';
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CustomCursorEN from "./cursorapp";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MyProjectsEN = () => {
    const [infoProject, setInfoProject] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    const navigate = useNavigate();

    function redirectHome() {
        navigate("/en");
    }

    const openCardProject = (project) => {
        setSelectedProject(project);
        setInfoProject(true);
    }

    const closeCardProject = () => {
        setInfoProject(false);
    }

    const notPosted = () => toast('🚀 Project not posted!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: {
            fontFamily: 'Open sans',
            fontSize: '35px',
        },
    });

    const notDisponible = () => toast('🚀 Private project! Particular effect.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: {
            fontFamily: 'Open sans',
            fontSize: '35px',
        },
    });

    return (
        <section>
            <CustomCursorEN />

            <div> <video src={videoBg} autoPlay loop muted poster={posterBg} /> </div>
            <div className="section-title">
                <h1>My Projects</h1>
            </div>
            <div className="cards-container">
                <div className="cards-projects">
                    <div className="pack-projects">

                        <div className="project">
                            <img src={imgPrj1} onClick={() => openCardProject("projeto1")} />
                            <div className="project-name"><span>My Invoices</span></div>
                            <div className="dropdown-content">
                                <h5>July 17, 2023</h5>
                                <button className="see-more" onClick={() => openCardProject("projeto1")}>View More</button>
                            </div>
                        </div>

                        <div className="project">
                            <img src={imgPrj2} onClick={() => openCardProject("projeto2")} />
                            <div className="project-name"><span>AgencyIA Landing Page</span></div>
                            <div className="dropdown-content">
                                <h5>January 31, 2023</h5>
                                <button className="see-more" onClick={() => openCardProject("projeto2")}>View More</button>
                            </div>
                        </div>

                        <div className="project">
                            <img src={imgPrj3} onClick={() => openCardProject("projeto3")} />
                            <div className="project-name"><span>Marriage Application</span></div>
                            <div className="dropdown-content">
                                <h5>June 22, 2023</h5>
                                <button className="see-more" onClick={() => openCardProject("projeto3")}>View More</button>
                            </div>
                        </div>

                    </div>

                    <div className="pack-projects">

                        <div className="project">
                            <img src={imgPrj4} onClick={() => openCardProject("projeto4")} />
                            <div className="project-name"><span>BMI Calculator</span></div>
                            <div className="dropdown-content">
                                <h5>January 30, 2023</h5>
                                <button className="see-more" onClick={() => openCardProject("projeto4")}>View More</button>
                            </div>
                        </div>

                        <div className="project">
                            <img src={imgPrj5} onClick={() => openCardProject("projeto5")} />
                            <div className="project-name"><span>Note Tasks</span></div>
                            <div className="dropdown-content">
                                <h5>February 9, 2023</h5>
                                <button className="see-more" onClick={() => openCardProject("projeto5")}>View More</button>
                            </div>
                        </div>

                        <div className="project">
                            <img src={imgPrj6} onClick={() => openCardProject("projeto6")} />
                            <div className="project-name"><span>G10 App</span></div>
                            <div className="dropdown-content">
                                <h5>July 9, 2023</h5>
                                <button className="see-more" onClick={() => openCardProject("projeto6")}>View More</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="back-to-home-tr">
                <span>
                    <AiOutlineHome color="#892cdc" size={25} onClick={redirectHome} />
                </span>
            </div>


            {infoProject ? (

                <div className="background-container">

                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />
                    {/* Same as */}
                    <ToastContainer />

                    {selectedProject === "projeto1" ? (

                        <div className="project-container">

                            <div className="project-left">
                                <div className="video-project">
                                    <iframe src="https://streamable.com/e/8da5ct?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay"></iframe>
                                </div>
                                <div className="buttons-project">
                                    <a onClick={notPosted} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        LinkedIn
                                    </a>
                                    <a onClick={notDisponible} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        GitHub
                                    </a>
                                    <a onClick={notDisponible} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        Access Project
                                    </a>
                                </div>
                            </div>

                            <div className="project-right">
                                <div className="buttons-project">
                                    <h1>My Invoices</h1>
                                    <h5>The project called My Invoices was my biggest scalable project done. Developed on demand for a company with the purpose of creating a flow and organization of documents and invoices, the use of this system's functions is divided into 3 types of users:
                                        <br />
                                        <br />
                                        • User sending documents (Only has access to documents that were sent by themselves.)
                                        <br />
                                        • Document receiving user (Has access to documents sent by all users and handles receipt.)
                                        <br />
                                        • Master User (Has access to all documents, releases, configurations and analyzes in dashboard format.)
                                        <br />
                                        <br />
                                        All of this segmentation takes place through a visual login screen, whose data is hosted on an AWS Cloud server/Database and all sent files are also stored.
                                        <br />
                                        <br />
                                        For this system, I used:
                                        <br />
                                        🎯 React
                                        <br />
                                        🎯 AWS Cloud
                                    </h5>
                                    <h4>July 17, 2023</h4>
                                </div>
                            </div>

                            <span className="close-card" onClick={closeCardProject}><IoClose color="black" size={17} /></span>
                        </div>

                    ) : selectedProject === "projeto2" ? (

                        <div className="project-container">

                            <div className="project-left">
                                <div className="video-project">
                                    <iframe src="https://streamable.com/e/ekwyd3?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay"></iframe>
                                </div>
                                <div className="buttons-project">
                                    <a href="https://www.linkedin.com/posts/silas-pires_dev-javascript-html-activity-7022283172319014913-L6Av/" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        LinkedIn
                                    </a>
                                    <a href="https://github.com/SilasPires/landing-page-portfolio" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        GitHub
                                    </a>
                                    <a href="https://agencyiaportfolio.netlify.app" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        Access Project
                                    </a>
                                </div>
                            </div>

                            <div className="project-right">
                                <div className="buttons-project">
                                    <h1>AgencyIA Landing Page</h1>
                                    <h5>Landing pages are a big passion for me. One of my first projects was the AgencyIA landing page.
                                        <br />
                                        <br />
                                        This landing page was made exclusively with HTML and CSS, with as little JavaScript as possible, having a more modern and minimalist approach without the need for automation, just as a direct presentation.                                        <br />
                                        <br />
                                        For this project, I used:
                                        <br />
                                        🎯 HTML
                                        <br />
                                        🎯 CSS
                                        <br />
                                        🎯 JavaScript
                                    </h5>
                                    <h4>January 31, 2023</h4>
                                </div>
                            </div>

                            <span className="close-card" onClick={closeCardProject}><IoClose color="black" size={17} /></span>
                        </div>

                    ) : selectedProject === "projeto3" ? (

                        <div className="project-container">

                            <div className="project-left">
                                <div className="video-project">
                                    <iframe src="https://streamable.com/e/aql8k4?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay"></iframe>
                                </div>
                                <div className="buttons-project">
                                    <a onClick={notPosted} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        LinkedIn
                                    </a>
                                    <a onClick={notDisponible} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        GitHub
                                    </a>
                                    <a href="http://casamentomarinaesilas.com.br" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        Access Project
                                    </a>
                                </div>
                            </div>

                            <div className="project-right">
                                <div className="buttons-project">
                                    <h1>Marriage Application</h1>
                                    <h5>This was without a doubt the most exciting project I've done, and also one of the most complex.
                                        <br />
                                        With a somewhat minimalist and sophisticated approach, I chose to create a front end design that was not massive, that was comfortable and admirable for each user.
                                        I used highlights like a responsive navbar, a background soundtrack with fixed playback controls, and smooth transitions with photos between sections.
                                        <br />
                                        I also used many backend resources to make the application unique and engaging.
                                        <br />
                                        So I created:
                                        <br />
                                        <br />
                                        • Login Screen (Each guest's invitation had a QR with the website's login code; when the code was entered, they automatically logged in with their name, thus allowing the user to be identified in all events on the website.)
                                        <br />
                                        • Gift List (A fully dynamic list, with categories, sum of values, shopping cart and message field and sending processing with AJAX. The user's request was sent to an email that I created previously and was later forwarded to the user an external payment link.)
                                        <br />
                                        • Attendance List (The attendance list was easy, useful and effective. When logging into the website, the user had only two options on the list, whether to attend the event or not, which, once the questionnaire was answered, was linked to a database of all guests and their status which was also shown in the application.)
                                        <br />
                                        <br />
                                        For this project, I used:
                                        <br />
                                        🎯 HTML
                                        <br />
                                        🎯 CSS
                                        <br />
                                        🎯 JavaScript
                                        <br />
                                        🎯 JQuery
                                    </h5>
                                    <h4>June 22, 2023</h4>
                                </div>
                            </div>

                            <span className="close-card" onClick={closeCardProject}><IoClose color="black" size={17} /></span>
                        </div>

                    ) : selectedProject === "projeto4" ? (

                        <div className="project-container">

                            <div className="project-left">
                                <div className="video-project">
                                    <iframe src="https://streamable.com/e/iejzy3?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay"></iframe>
                                </div>
                                <div className="buttons-project">
                                    <a href="https://www.linkedin.com/posts/silas-pires_dev-javascript-html-activity-7025964363354124288-N0a5/" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        LinkedIn
                                    </a>
                                    <a href="https://github.com/SilasPires/calculadora-imc-javascript" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        GitHub
                                    </a>
                                    <a href="https://agencyiacalculadoraimc.netlify.app" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        Access Project
                                    </a>
                                </div>
                            </div>

                            <div className="project-right">
                                <div className="buttons-project">
                                    <h1>BMI Calculator</h1>
                                    <h5>This BMI calculator was developed to test and test my knowledge of JavaScript.
                                        <br />
                                        With a very simple design, this calculator aims to alert the user about their body health, making a relationship between height and weight.
                                        <br />
                                        <br />
                                        For this project, I used:
                                        <br />
                                        🎯 HTML
                                        <br />
                                        🎯 CSS
                                        <br />
                                        🎯 JavaScript
                                    </h5>
                                    <h4>January 30, 2023</h4>
                                </div>
                            </div>

                            <span className="close-card" onClick={closeCardProject}><IoClose color="black" size={17} /></span>
                        </div>

                    ) : selectedProject === "projeto5" ? (

                        <div className="project-container">

                            <div className="project-left">
                                <div className="video-project">
                                    <iframe src="https://streamable.com/e/wa83lw?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay"></iframe>
                                </div>
                                <div className="buttons-project">
                                    <a href="https://www.linkedin.com/posts/silas-pires_dev-javascript-html-activity-7029589563584970752-74wj/" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        LinkedIn
                                    </a>
                                    <a onClick={notDisponible} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        GitHub
                                    </a>
                                    <a href="https://agencyianotetasks.netlify.app" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        Access Project
                                    </a>
                                </div>
                            </div>

                            <div className="project-right">
                                <div className="buttons-project">
                                    <h1>Note Tasks</h1>
                                    <h5>The quick notes application that I called "Note Tasks" was created with the aim of eliminating sticky notes and paper.
                                        I opted for a quick approach that was efficient to the point of leaving a record/reminder in just a few seconds until it was completed by the user.
                                        <br />
                                        Notes are typed and saved using LocalStorage, a procedure that allows the user to have their notes visible only to themselves and opened only on their device.
                                        It is also worth remembering that notes are stored until the user marks them as completed, otherwise there is the possibility of closing the browser or even turning off the device and the notes will continue to be saved.
                                        <br />
                                        <br />
                                        For this project, I used:
                                        <br />
                                        🎯 HTML
                                        <br />
                                        🎯 CSS
                                        <br />
                                        🎯 JavaScript
                                    </h5>
                                    <h4>February 9, 2023</h4>
                                </div>
                            </div>

                            <span className="close-card" onClick={closeCardProject}><IoClose color="black" size={17} /></span>
                        </div>

                    ) : selectedProject === "projeto6" ? (

                        <div className="project-container">

                            <div className="project-left">
                                <div className="video-project">
                                    <iframe src="https://streamable.com/e/ixgw9a?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay"></iframe>
                                </div>
                                <div className="buttons-project">
                                    <a onClick={notPosted} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        LinkedIn
                                    </a>
                                    <a onClick={notDisponible} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        GitHub
                                    </a>
                                    <a onClick={notDisponible} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        Access Project
                                    </a>
                                </div>
                            </div>

                            <div className="project-right">
                                <div className="buttons-project">
                                    <h1>G10 App</h1>
                                    <h5>The G10 application was an application developed on demand for a group of people who meet to play games.
                                        The rules are that the games do not have a pre-established number of rounds, 4 people play at a time and generally lose two people, who continue and the other two who won leave to make way for the next 2 competitors.
                                        After several cycles of rounds of quantities not defined at the start, the players who have the most defeats (called launderers) are the ones who suffer a penalty.
                                        <br />
                                        <br/>
                                        To meet these criteria and rules, I used list classification, mapping, and array switching. I created rankings and the next update will be to integrate it into an online database so that all players have simultaneous access to results and matches.
                                        <br />
                                        <br />
                                        For this application, I used:
                                        <br />
                                        🎯 React Native
                                    </h5>
                                    <h4>July 9, 2023</h4>
                                </div>
                            </div>

                            <span className="close-card" onClick={closeCardProject}><IoClose color="black" size={17} /></span>
                        </div>

                    ) : (
                        <></>
                    )}

                </div>

            ) : (
                <></>

            )}
        </section>
    )
}

export default MyProjectsEN;