import React, { useState, useEffect } from 'react';

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const updateCursorPosition = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    document.addEventListener('mousemove', updateCursorPosition);

    return () => {
      document.removeEventListener('mousemove', updateCursorPosition);
    };
  }, []);

  const cursorStyles = {
    position: 'fixed',
    left: `${position.x}px`,
    top: `${position.y}px`,
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    border: '2px solid #892cdc',
    backgroundColor: 'transparent',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
    zIndex: 9999999999,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const dotStyles = {
    width: '3px',
    height: '3px',
    borderRadius: '50%',
    backgroundColor: '#892cdc',
  };

  return (
    <div style={cursorStyles}>
      <div style={dotStyles}></div>
    </div>
  );
};

export default CustomCursor;
