import React, { useState, useEffect } from "react";
import videoBg from '../img/bg.mp4';
import posterBg from '../img/bg.png';
import loadingImg from '../img/zigzagloading.svg';
import spLogo from '../img/logo-sp.png';
import CustomCursor from "./cursorapp";

const LoadingScreen = () => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsVisible(false);
        }, 4000);
        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <section className="loading-screen">
            <CustomCursor/>
            <div> <video src={videoBg} autoPlay loop muted poster={posterBg} /> </div>
            <div className="iconLoading">
                {isVisible ? (
                    <img className="lg-loading" src={loadingImg} alt="Zig Zag Loading" />
                ) : (
                    <img className="lg-sp" src={spLogo} alt="Zig Zag Loading" />
                )}
            </div>

        </section>
    )
}

export default LoadingScreen;

