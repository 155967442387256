import React from "react";
import videoBg from '../img/bg.mp4';
import posterBg from '../img/bg.png';
import { AiOutlineHome } from "react-icons/ai";
import { FaInstagram, FaGithub, FaLinkedin } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CustomCursor from "./cursorapp";

const ContactMe = () => {
    const navigate = useNavigate();

    function redirectHome() {
        navigate("/");
    }


    return (
        <section>
            <CustomCursor/>
            <div> <video src={videoBg} autoPlay loop muted poster={posterBg} /> </div>

            <div className="section-title">
                <h1>Fale Comigo</h1>
            </div>

            <div className="container-section">
                <div className="container-flex">

                    <div className="contact-form">
                        <form action="https://formsubmit.co/744ddcadd254efe73c4579a4ba7d2b2f" method="POST">
                            <input type="hidden" name="_subject" value="Nova Mensagem do Portfólio" />
                            <input type="hidden" name="_captcha" value="false" />
                            <input type="hidden" name="_next" value="http://localhost:3000/sent"></input>
                            <input type="text" name="name" required placeholder="Digite seu nome" />
                            <input type="email" name="email" required placeholder="Digite seu e-mail" />
                            <textarea name="message" placeholder="Escreva sua mensagem"></textarea>
                            <button type="submit">Enviar mensagem</button>
                        </form>
                    </div>

                    <div className="social-network">
                        <a href="https://www.instagram.com/silaspires.13/" target="_blank" rel="noopener noreferrer"><span><FaInstagram className="iconRs" color="#892cdc" /></span></a>
                        <a href="https://github.com/SilasPires" target="_blank" rel="noopener noreferrer"><span><FaGithub className="iconRs" color="#892cdc" /></span></a>
                        <a href="https://www.linkedin.com/in/silas-pires/" target="_blank" rel="noopener noreferrer"><span><FaLinkedin className="iconRs" color="#892cdc" /></span></a>
                        <a href="mailto:pro.silaspires@gmail.com" target="_blank" rel="noopener noreferrer"><span><MdMail className="iconRs" color="#892cdc" /></span></a>
                    </div>


                </div>
            </div>

            <div className="back-to-home-tr">
                <span>
                    <AiOutlineHome color="#892cdc" size={25} onClick={redirectHome} />
                </span>
            </div>
        </section>
    )
}

export default ContactMe;