import React from "react";
import videoBg from '../img/bg.mp4';
import posterBg from '../img/bg.png';
import { AiOutlineHome } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import CustomCursor from "./cursorapp";

const ThanksScreen = () => {
    const navigate = useNavigate();

    function redirectHome() {
        navigate("/");
    }


    return (
        <section>
            <CustomCursor/>
            <div> <video src={videoBg} autoPlay loop muted poster={posterBg} /> </div>

            <div className="section-msg">
                <h1>Sua mensagem foi enviada com sucesso!</h1>
                <h2>Responderei o mais breve possível.</h2>
            </div>

            <div className="back-to-home-tr">
                <span>
                    <AiOutlineHome color="#892cdc" size={25} onClick={redirectHome} />
                </span>
            </div>
        </section>
    )
}

export default ThanksScreen;