import React, { useState } from "react";
import videoBg from '../img/bg.mp4';
import posterBg from '../img/bg.png';
import jstph from '../img/javascripttrophy.png';
import htmltph from '../img/htmltrophy.png';
import pytph from '../img/pythonptrophy.png';
import typetph from '../img/typetrophy.png';
import vbatph from '../img/vbatrophy.png';
import csstph from '../img/csstrophy.png';
import rcttph from '../img/reacttrophy.png';
import rctntvph from '../img/nativetrophy.png';
import nodetph from '../img/nodetrophy.png';
import angtph from '../img/angulartrophy.png';
import vuetph from '../img/vuetrophy.png';
import mdbtph from '../img/mongodbtrophy.png';
import awstph from '../img/awstrophy.png';
import fgmatph from '../img/figmatrophy.png';
import pstph from '../img/photoshoptrophy.png';
import aitph from '../img/illustratortrophy.png';
import cvatph from '../img/canvatrophy.png';
import aetph from '../img/aftereffectstrophy.png';
import cdtph from '../img/coreldrawtrophy.png';
import { AiOutlineHome } from "react-icons/ai";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CustomCursorEN from "./cursorapp";

const MinhasCompetenciasEN = () => {
        const [infoTrophy, setInfoTrophy] = useState(null);
        const [countProjects, setCountProjects] = useState(0);
        const [intervalRef, setIntervalRef] = useState(null);
        const [selectedTrophy, setSelectedTrophy] = useState(null);
        const [countYears, setCountYears] = useState(0);
        const [intervalRef2, setIntervalRef2] = useState(null);

        const navigate = useNavigate();

        function redirectHome() {
                navigate("/en");
        }

        const openCardInfo = (trophy) => {
                setSelectedTrophy(trophy);
                setInfoTrophy(true);
                const projectsForTrophy = getProjectsForTrophy(trophy);
                const yearsExpForTrophy = getYearsExpForTrophy(trophy);

                numberOfProjectsAnimate(projectsForTrophy);
                numberYearsOfExperience(yearsExpForTrophy);
        };

        const closeCardInfo = () => {
                setInfoTrophy(false);
                setCountProjects(0);
                setCountYears(0);
                clearInterval(intervalRef);
                clearInterval(intervalRef2);
        }

        const getProjectsForTrophy = (trophy) => {
                switch (trophy) {
                        case "javascript":
                                return 5;
                        case "html":
                                return 7;
                        case "python":
                                return 1;
                        case "typescript":
                                return 2;
                        case "vba":
                                return 2;
                        case "css":
                                return 7;
                        case "native":
                                return 1;
                        case "react":
                                return 4;
                        case "node":
                                return 4;
                        case "angular":
                                return 1;
                        case "vue":
                                return 1;
                        case "mongo":
                                return 1;
                        case "aws":
                                return 1;
                        case "figma":
                                return 10;
                        case "photoshop":
                                return 5;
                        case "illustrator":
                                return 20;
                        case "canva":
                                return 25;
                        case "after":
                                return 2;
                        case "corel":
                                return 10;
                        default:
                                return 0;
                }
        };

        const getYearsExpForTrophy = (trophy) => {
                switch (trophy) {
                        case "javascript":
                                return 2;
                        case "html":
                                return 3;
                        case "python":
                                return 1;
                        case "typescript":
                                return 1;
                        case "vba":
                                return 2;
                        case "css":
                                return 3;
                        case "native":
                                return 1;
                        case "react":
                                return 2;
                        case "node":
                                return 2;
                        case "angular":
                                return 1;
                        case "vue":
                                return 1;
                        case "mongo":
                                return 1;
                        case "aws":
                                return 1;
                        case "figma":
                                return 1;
                        case "photoshop":
                                return 1;
                        case "illustrator":
                                return 2;
                        case "canva":
                                return 5;
                        case "after":
                                return 1;
                        case "corel":
                                return 5;
                        default:
                                return 0;
                }
        };

        const numberOfProjectsAnimate = (projectsForTrophy) => {
                const interval = setInterval(() => {

                        setCountProjects((prevCount) => {
                                if (prevCount < projectsForTrophy) {
                                        return prevCount + 1;
                                } else {

                                        clearInterval(interval);
                                        return projectsForTrophy;
                                }
                        });
                }, 500);

                setIntervalRef(interval);
        };

        const numberYearsOfExperience = (yearsExpForTrophy) => {
                const interval = setInterval(() => {

                        setCountYears((prevCount) => {
                                if (prevCount < yearsExpForTrophy) {
                                        return prevCount + 1;
                                } else {

                                        clearInterval(interval);
                                        return yearsExpForTrophy;
                                }
                        });
                }, 500);

                setIntervalRef2(interval);
        };

        const trophyImg = () => {
                if (selectedTrophy === null) { return null }
                if (selectedTrophy === "javascript") { return jstph }
                if (selectedTrophy === "html") { return htmltph }
                if (selectedTrophy === "python") { return pytph }
                if (selectedTrophy === "typescript") { return typetph }
                if (selectedTrophy === "vba") { return vbatph }
                if (selectedTrophy === "css") { return csstph }
                if (selectedTrophy === "native") { return rctntvph }
                if (selectedTrophy === "react") { return rcttph }
                if (selectedTrophy === "node") { return nodetph }
                if (selectedTrophy === "angular") { return angtph }
                if (selectedTrophy === "vue") { return vuetph }
                if (selectedTrophy === "mongo") { return mdbtph }
                if (selectedTrophy === "aws") { return awstph }
                if (selectedTrophy === "figma") { return fgmatph }
                if (selectedTrophy === "photoshop") { return pstph }
                if (selectedTrophy === "illustrator") { return aitph }
                if (selectedTrophy === "canva") { return cvatph }
                if (selectedTrophy === "after") { return aetph }
                if (selectedTrophy === "corel") { return cdtph }
        }

        const trophyName = () => {
                if (selectedTrophy === null) { return null }
                if (selectedTrophy === "javascript") { return "JavaScript" }
                if (selectedTrophy === "html") { return "HTML" }
                if (selectedTrophy === "python") { return "Python" }
                if (selectedTrophy === "typescript") { return "TypeScript" }
                if (selectedTrophy === "vba") { return "Excel VBA" }
                if (selectedTrophy === "css") { return "CSS" }
                if (selectedTrophy === "native") { return "React Native" }
                if (selectedTrophy === "react") { return "React" }
                if (selectedTrophy === "node") { return "Node Js" }
                if (selectedTrophy === "angular") { return "Angular" }
                if (selectedTrophy === "vue") { return "Vue" }
                if (selectedTrophy === "mongo") { return "Mongo DB" }
                if (selectedTrophy === "aws") { return "AWS Cloud" }
                if (selectedTrophy === "figma") { return "Figma" }
                if (selectedTrophy === "photoshop") { return "Photoshop" }
                if (selectedTrophy === "illustrator") { return "Illustrator" }
                if (selectedTrophy === "canva") { return "Canva" }
                if (selectedTrophy === "after") { return "After Effects" }
                if (selectedTrophy === "corel") { return "Corel Draw" }
        }

        const trophyDescription = () => {
                if (selectedTrophy === null) { return null }
                if (selectedTrophy === "javascript") { return "Javascript was my first passion in the world of programming. I have enough technical and practical knowledge to embark on large projects alone or in a group in a scalable way." }
                if (selectedTrophy === "html") { return "Programming and developing with HTML is indescribable fun, I use it whenever possible in my projects. I am fully aware of the benefits that HTML can offer." }
                if (selectedTrophy === "typescript") { return "I have prior knowledge and am continually studying developing myself in Typescript." }
                if (selectedTrophy === "vba") { return "I have a great appreciation for spreadsheets and skills that allow me to do great things through Excel. I gained some knowledge of VBA and made my spreadsheets like real software." }
                if (selectedTrophy === "css") { return "The old generic CSS... See, all the content on this page has a bunch of CSS in the UI." }
                if (selectedTrophy === "native") { return "I have reasonable knowledge, I can develop very complex applications and I continue to constantly learn." }
                if (selectedTrophy === "react") { return "Currently, I work in the react world, I have a good knowledge, but I still have knowledge to cover." }
                if (selectedTrophy === "node") { return "The Node is... the node." }
                if (selectedTrophy === "angular") { return "I appreciate this framework, considered, specific and decisive. I'm studying and dedicating myself." }
                if (selectedTrophy === "vue") { return "I appreciate this framework, considered, specific and decisive. I'm studying and dedicating myself." }
                if (selectedTrophy === "mongo") { return "This is without a doubt one of my favorite noSQL database options." }
                if (selectedTrophy === "aws") { return "The AWS Cloud trauma no longer embarrasses me." }
                if (selectedTrophy === "figma") { return "Great design skills in general, plugins..." }
                if (selectedTrophy === "photoshop") { return "Little knowledge, I do the minimum, but what is necessary." }
                if (selectedTrophy === "illustrator") { return "Good knowledge, working with vectors, curves, logos and design in general." }
                if (selectedTrophy === "canva") { return "For quick, non-complex designs and projects." }
                if (selectedTrophy === "after") { return "Basic knowledge and I only do what is necessary." }
                if (selectedTrophy === "corel") { return "I don't use it anymore, but I have a good knowledge of it." }
        }

        const starRating = () => {
                if (selectedTrophy === null) { return null }
                if (selectedTrophy === "javascript") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "html") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "python") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "typescript") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "vba") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "css") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "native") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "react") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "node") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "angular") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "vue") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "mongo") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "aws") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "figma") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "photoshop") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStarHalfAlt color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "illustrator") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "canva") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "after") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
                if (selectedTrophy === "corel") {
                        return (
                                <div className="rating">
                                        <span>Knowledge level</span>
                                        <br />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaStar color="#5f03b0" size={30} />
                                        <FaRegStar color="#5f03b0" size={30} />
                                </div>
                        )
                }
        }

        return (
                <section className="trophyBg">
                        <CustomCursorEN />
                        <div> <video src={videoBg} autoPlay loop muted poster={posterBg} /> </div>
                        <div className="galery-trophy">
                                <div className="section-trophies">
                                        <div className="trophies1">
                                                <img src={jstph} width={80} onClick={() => openCardInfo("javascript")} />
                                                <img src={htmltph} width={80} onClick={() => openCardInfo("html")} />
                                                <img src={pytph} width={80} onClick={() => openCardInfo("python")} />
                                                <img src={typetph} width={80} onClick={() => openCardInfo("typescript")} />
                                                <img src={vbatph} width={80} onClick={() => openCardInfo("vba")} />
                                                <img src={csstph} width={80} onClick={() => openCardInfo("css")} />
                                        </div>

                                        <div className="text-divider">
                                                <h2 className="description">Languages</h2>
                                        </div>
                                </div>

                                <span></span>

                                <div className="section-trophies">
                                        <div className="trophies1">
                                                <img src={rctntvph} width={80} onClick={() => openCardInfo("native")} />
                                                <img src={rcttph} width={80} onClick={() => openCardInfo("react")} />
                                                <img src={nodetph} width={80} onClick={() => openCardInfo("node")} />
                                                <img src={angtph} width={80} onClick={() => openCardInfo("angular")} />
                                                <img src={vuetph} width={80} onClick={() => openCardInfo("vue")} />
                                        </div>

                                        <div className="text-divider">
                                                <h2 className="description">Frameworks</h2>
                                        </div>
                                </div>

                                <span></span>

                                <div className="section-trophies">
                                        <div className="trophies1">
                                                <img src={mdbtph} width={80} onClick={() => openCardInfo("mongo")} />
                                                <img src={awstph} width={80} onClick={() => openCardInfo("aws")} />
                                        </div>

                                        <div className="text-divider">
                                                <h2 className="description">Databases</h2>
                                        </div>
                                </div>

                                <span></span>

                                <div className="section-trophies">
                                        <div className="trophies1">
                                                <img src={fgmatph} width={80} onClick={() => openCardInfo("figma")} />
                                                <img src={pstph} width={80} onClick={() => openCardInfo("photoshop")} />
                                                <img src={aitph} width={80} onClick={() => openCardInfo("illustrator")} />
                                                <img src={cvatph} width={80} onClick={() => openCardInfo("canva")} />
                                                <img src={aetph} width={80} onClick={() => openCardInfo("after")} />
                                                <img src={cdtph} width={80} onClick={() => openCardInfo("corel")} />
                                        </div>

                                        <div className="text-divider">
                                                <h2 className="description">Design Tools</h2>
                                        </div>
                                </div>
                        </div>

                        <div className="back-to-home">
                                <span>
                                        <AiOutlineHome color="#892cdc" size={25} onClick={redirectHome} />
                                </span>
                        </div>


                        {infoTrophy ? (

                                <div className="description-container">
                                        <div className="card-container">

                                                <div className="card-trophy">
                                                        <img src={trophyImg()} width={280} />
                                                </div>

                                                <div className="card-trophy-desc">
                                                        <h1>{trophyName()}</h1>
                                                        {starRating()}
                                                        <div className="counters">
                                                                <div>
                                                                        <h3>Projects</h3>
                                                                        <h2>{countProjects}+</h2>
                                                                </div>
                                                                <div>
                                                                        <h3>Years of experience</h3>
                                                                        <h2>{countYears}+</h2>
                                                                </div>
                                                        </div>
                                                        <div className="description-card">
                                                                <p>{trophyDescription()}</p>
                                                        </div>
                                                </div>

                                        </div>

                                        <span className="close-card" onClick={closeCardInfo}><IoClose color="black" size={17} /></span>

                                </div>

                        ) : (
                                <></>

                        )}

                </section>
        );

}

export default MinhasCompetenciasEN;