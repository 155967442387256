import React, { useState, useEffect } from "react";
import videoBg from '../img/bg.mp4';
import posterBg from '../img/bg.png';
import logoSp from '../img/logo-sp.png';
import bandBr from '../img/bandbr.png';
import BandEua from '../img/bandeua.png';
import RoundaboutRightIcon from '@mui/icons-material/RoundaboutRight';
import Menu from "./menu";
import TypeWriterComp from "./typewriteeffect";
import LoadingScreen from "./loading";
import { useNavigate } from "react-router-dom";
import CustomCursor from "./cursorapp";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  function redirectProjects() {
    navigate("/projects");
  }

  function redirectAboutme() {
    navigate("/aboutme");
  }

  function redirectChangeLang() {
    navigate("/en");
  }

  useEffect(() => {
      const timeoutId = setTimeout(() => {
          setIsLoading(false);
      }, 6500);
      return () => clearTimeout(timeoutId);
  }, []);

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [activeBand, setActiveBand] = useState('band1');

  const hamburguer = () => {
    setHamburgerOpen(!hamburgerOpen);
  }

  const changeLang = () => {
    setTimeout(() => {
      redirectChangeLang();
    }, 1200);
  };

  const handleClick = () => {
    changeLang();
    setActiveBand((prevActiveBand) =>
      prevActiveBand === 'band1' ? 'band2' : 'band1'
    );
  };

  return (
    <>
    {isLoading ? ( 
      <LoadingScreen/>
    ) : (

    <section>
      <CustomCursor/>

      <div> <video src={videoBg} autoPlay loop muted poster={posterBg} /> </div>

      <div className="logoSp">
        <img src={logoSp} width={28} />
      </div>

      <div className="info-ride">

        <div className={`${activeBand === 'band1' ? 'banders' : 'banders2'}`} onClick={handleClick}>
      <div className={`band1 ${activeBand === 'band1' ? 'active' : ''}`}>
        <img src={bandBr} width={15} alt="Band BR" />
      </div>
      <div className={`band2 ${activeBand === 'band2' ? 'active' : ''}`}>
        <img src={BandEua} width={15} alt="Band EUA" />
      </div>
    </div>

        <div className={`hamburger ${hamburgerOpen ? 'open' : ''}`} onClick={hamburguer}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      {hamburgerOpen ? (

        <Menu/>

      ) : (

        <div className="introcing">
          
          <TypeWriterComp/>
          
          <div className="pages">
            <div className="sec-pages">
              <RoundaboutRightIcon style={{ fontSize: '1.8rem', marginRight: '-1rem' }} />
              <h3 onClick={redirectProjects}>Veja meus projetos</h3>
            </div>
            <div className="sec-pages">
              <RoundaboutRightIcon style={{ fontSize: '1.8rem', marginRight: '-1rem' }} />
              <h3 onClick={redirectAboutme}>Mais sobre mim</h3>
            </div>
          </div>
        </div>

      )}

    </section>
      )}
    </>
  );
}

export default Home;

