import React, { useState } from "react";
import videoBg from '../img/bg.mp4';
import posterBg from '../img/bg.png';
import { AiOutlineHome } from "react-icons/ai";
import imgPrj1 from '../img/invoices_screen.png';
import imgPrj2 from '../img/landingia_screen.png';
import imgPrj3 from '../img/marriage_screen.png';
import imgPrj4 from '../img/imc_screen.png';
import imgPrj5 from '../img/note_screen.png';
import imgPrj6 from '../img/g10_screen.png';
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CustomCursor from "./cursorapp";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MyProjects = () => {
    const [infoProject, setInfoProject] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    const navigate = useNavigate();

    function redirectHome() {
        navigate("/");
    }

    const openCardProject = (project) => {
        setSelectedProject(project);
        setInfoProject(true);
    }

    const closeCardProject = () => {
        setInfoProject(false);
    }

    const notPosted = () => toast('🚀 Projeto não postado!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: {
            fontFamily: 'Open sans',
            fontSize: '35px',
        },
    });

    const notDisponible = () => toast('🚀 Projeto privado! Efeito particular.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: {
            fontFamily: 'Open sans',
            fontSize: '35px',
        },
    });

    return (
        <section>
            <CustomCursor />

            <div> <video src={videoBg} autoPlay loop muted poster={posterBg} /> </div>
            <div className="section-title">
                <h1>Meus Projetos</h1>
            </div>
            <div className="cards-container">
                <div className="cards-projects">
                    <div className="pack-projects">

                        <div className="project">
                            <img src={imgPrj1} onClick={() => openCardProject("projeto1")} />
                            <div className="project-name"><span>My Invoices</span></div>
                            <div className="dropdown-content">
                                <h5>17 de Julho de 2023</h5>
                                <button className="see-more" onClick={() => openCardProject("projeto1")}>Ver Mais</button>
                            </div>
                        </div>

                        <div className="project">
                            <img src={imgPrj2} onClick={() => openCardProject("projeto2")} />
                            <div className="project-name"><span>AgencyIA Landing Page</span></div>
                            <div className="dropdown-content">
                                <h5>31 de Janeiro de 2023</h5>
                                <button className="see-more" onClick={() => openCardProject("projeto2")}>Ver Mais</button>
                            </div>
                        </div>

                        <div className="project">
                            <img src={imgPrj3} onClick={() => openCardProject("projeto3")} />
                            <div className="project-name"><span>Aplicação de Casamento</span></div>
                            <div className="dropdown-content">
                                <h5>22 de Junho de 2023</h5>
                                <button className="see-more" onClick={() => openCardProject("projeto3")}>Ver Mais</button>
                            </div>
                        </div>

                    </div>

                    <div className="pack-projects">

                        <div className="project">
                            <img src={imgPrj4} onClick={() => openCardProject("projeto4")} />
                            <div className="project-name"><span>Calculadora de IMC</span></div>
                            <div className="dropdown-content">
                                <h5>30 de Janeiro de 2023</h5>
                                <button className="see-more" onClick={() => openCardProject("projeto4")}>Ver Mais</button>
                            </div>
                        </div>

                        <div className="project">
                            <img src={imgPrj5} onClick={() => openCardProject("projeto5")} />
                            <div className="project-name"><span>Note Tasks</span></div>
                            <div className="dropdown-content">
                                <h5>09 de Fevereiro de 2023</h5>
                                <button className="see-more" onClick={() => openCardProject("projeto5")}>Ver Mais</button>
                            </div>
                        </div>

                        <div className="project">
                            <img src={imgPrj6} onClick={() => openCardProject("projeto6")} />
                            <div className="project-name"><span>Aplicativo G10</span></div>
                            <div className="dropdown-content">
                                <h5>09 de Julho de 2023</h5>
                                <button className="see-more" onClick={() => openCardProject("projeto6")}>Ver Mais</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="back-to-home-tr">
                <span>
                    <AiOutlineHome color="#892cdc" size={25} onClick={redirectHome} />
                </span>
            </div>


            {infoProject ? (

                <div className="background-container">

                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />
                    {/* Same as */}
                    <ToastContainer />

                    {selectedProject === "projeto1" ? (

                        <div className="project-container">

                            <div className="project-left">
                                <div className="video-project">
                                    <iframe src="https://streamable.com/e/8da5ct?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay"></iframe>
                                </div>
                                <div className="buttons-project">
                                    <a onClick={notPosted} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        LinkedIn
                                    </a>
                                    <a onClick={notDisponible} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        GitHub
                                    </a>
                                    <a onClick={notDisponible} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        Acessar Projeto
                                    </a>
                                </div>
                            </div>

                            <div className="project-right">
                                <div className="buttons-project">
                                    <h1>My Invoices</h1>
                                    <h5>O projeto denominado como My Invoices foi o meu grande projeto escalável feito. Desenvolvido sob demanda para uma empresa com a finalidade de criar um fluxo e organização de documentos e notas fiscais, a utilização das funções deste sistema é dividida em 3 tipos de usuários:
                                        <br />
                                        <br />
                                        • Usuário enviador de documentos (Apenas tem acesso aos documentos que foram enviados por si próprio.)
                                        <br />
                                        • Usuário receptor de documentos (Tem acesso aos documentos que foram enviados por todos os usuários e manipula o recebimento.)
                                        <br />
                                        • Usuário Master (Tem acesso a todos os documentos, liberações, configurações e análises em formato de dashboard.)
                                        <br />
                                        <br />
                                        Toda essa segmentação acontece através de uma visual tela de login, cujos dados estão hospedados num servidor/Banco de Dados da AWS Cloud e também armazenados todos os arquivos enviados.
                                        <br />
                                        <br />
                                        Para este sistema, utilizei:
                                        <br />
                                        🎯 React
                                        <br />
                                        🎯 AWS Cloud
                                    </h5>
                                    <h4>17 de Julho de 2023</h4>
                                </div>
                            </div>

                            <span className="close-card" onClick={closeCardProject}><IoClose color="black" size={17} /></span>
                        </div>

                    ) : selectedProject === "projeto2" ? (

                        <div className="project-container">

                            <div className="project-left">
                                <div className="video-project">
                                    <iframe src="https://streamable.com/e/ekwyd3?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay"></iframe>
                                </div>
                                <div className="buttons-project">
                                    <a href="https://www.linkedin.com/posts/silas-pires_dev-javascript-html-activity-7022283172319014913-L6Av/" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        LinkedIn
                                    </a>
                                    <a href="https://github.com/SilasPires/landing-page-portfolio" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        GitHub
                                    </a>
                                    <a href="https://agencyiaportfolio.netlify.app" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        Acessar Projeto
                                    </a>
                                </div>
                            </div>

                            <div className="project-right">
                                <div className="buttons-project">
                                    <h1>AgencyIA Landing Page</h1>
                                    <h5>Landing pages são uma grande paixão para mim. Um dos meus primeiros projetos foi a landing page da AgencyIA.
                                        <br />
                                        <br />
                                        Esta landing page foi feita exclusivamente com HTML e CSS, com o mínimo de JavaScript possível, possuindo uma abordagem mais moderna e minimalista sem a necessidade de automações, apenas como apresentação direta.
                                        <br />
                                        <br />
                                        Para este projeto, utilizei:
                                        <br />
                                        🎯 HTML
                                        <br />
                                        🎯 CSS
                                        <br />
                                        🎯 JavaScript
                                    </h5>
                                    <h4>31 de Janeiro de 2023</h4>
                                </div>
                            </div>

                            <span className="close-card" onClick={closeCardProject}><IoClose color="black" size={17} /></span>
                        </div>

                    ) : selectedProject === "projeto3" ? (

                        <div className="project-container">

                            <div className="project-left">
                                <div className="video-project">
                                    <iframe src="https://streamable.com/e/aql8k4?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay"></iframe>
                                </div>
                                <div className="buttons-project">
                                    <a onClick={notPosted} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        LinkedIn
                                    </a>
                                    <a onClick={notDisponible} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        GitHub
                                    </a>
                                    <a href="http://casamentomarinaesilas.com.br" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        Acessar Projeto
                                    </a>
                                </div>
                            </div>

                            <div className="project-right">
                                <div className="buttons-project">
                                    <h1>Aplicação de Casamento</h1>
                                    <h5>Este sem dúvidas foi o projetos mais emocionantes que fiz, também um dos mais complexos.
                                        <br />
                                        Com uma abordagem um tanto quanto minimalista e sofisticada, optei por criar um design front end que não fosse massivo, que fosse confortável e admirável para cada usuário.
                                        Utilizei destaques como uma navbar responsiva, uma trilha sonora de fundo com controles de reprodução fixos e passagens suaves com fotos entre as seções.
                                        <br />
                                        Também utilizei de muitos recursos de backend para deixar a aplicação única e envolvente.
                                        <br />
                                        Então criei:
                                        <br />
                                        <br />
                                        • Tela de Login (No convite de cada convidado tinha um QR com o código de login no site, ao colocar o código já logava automaticamente com o nome, permitindo assim a identificação do usuário em todos os eventos do site.)
                                        <br />
                                        • Lista de Presentes (Uma lista totalmente dinâmica, com categorias, soma de valores, carrinho de compras e campo para mensagem e processamento de envio com AJAX. O request do usuário era enviado para um e-mail que criei previamente e posteriormente era encaminhado para o usuário um link de pagamento externo.)
                                        <br />
                                        • Lista de Presença (A lista de presença era fácil, útil e eficaz. Ao logar no site, o usuário tinha apenas duas opções na lista, comparecer ou não ao evento, que logo que respondido o questionário, era vinculado a uma base de dados de todos os convidados e seu status que também era mostrado na aplicação.)
                                        <br />
                                        <br />
                                        Para este projeto, utilizei:
                                        <br />
                                        🎯 HTML
                                        <br />
                                        🎯 CSS
                                        <br />
                                        🎯 JavaScript
                                        <br />
                                        🎯 JQuery
                                    </h5>
                                    <h4>22 de Junho de 2023</h4>
                                </div>
                            </div>

                            <span className="close-card" onClick={closeCardProject}><IoClose color="black" size={17} /></span>
                        </div>

                    ) : selectedProject === "projeto4" ? (

                        <div className="project-container">

                            <div className="project-left">
                                <div className="video-project">
                                    <iframe src="https://streamable.com/e/iejzy3?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay"></iframe>
                                </div>
                                <div className="buttons-project">
                                    <a href="https://www.linkedin.com/posts/silas-pires_dev-javascript-html-activity-7025964363354124288-N0a5/" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        LinkedIn
                                    </a>
                                    <a href="https://github.com/SilasPires/calculadora-imc-javascript" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        GitHub
                                    </a>
                                    <a href="https://agencyiacalculadoraimc.netlify.app" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        Acessar Projeto
                                    </a>
                                </div>
                            </div>

                            <div className="project-right">
                                <div className="buttons-project">
                                    <h1>Calculadora de IMC</h1>
                                    <h5>Esta calculadora de IMC foi desenvolvida para testar e por a prova os meus conhecimentos com javascript.
                                        <br />
                                        Com um desing bem simples, esta calculadora tem como objetivo alertar o usuário sobre sua saúde corporal, fazendo a relação da altura x peso.
                                        <br />
                                        <br />
                                        Para este projeto, utilizei:
                                        <br />
                                        🎯 HTML
                                        <br />
                                        🎯 CSS
                                        <br />
                                        🎯 JavaScript
                                    </h5>
                                    <h4>30 de Janeiro de 2023</h4>
                                </div>
                            </div>

                            <span className="close-card" onClick={closeCardProject}><IoClose color="black" size={17} /></span>
                        </div>

                    ) : selectedProject === "projeto5" ? (

                        <div className="project-container">

                            <div className="project-left">
                                <div className="video-project">
                                    <iframe src="https://streamable.com/e/wa83lw?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay"></iframe>
                                </div>
                                <div className="buttons-project">
                                    <a href="https://www.linkedin.com/posts/silas-pires_dev-javascript-html-activity-7029589563584970752-74wj/" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        LinkedIn
                                    </a>
                                    <a onClick={notDisponible} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        GitHub
                                    </a>
                                    <a href="https://agencyianotetasks.netlify.app" className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        Acessar Projeto
                                    </a>
                                </div>
                            </div>

                            <div className="project-right">
                                <div className="buttons-project">
                                    <h1>Note Tasks</h1>
                                    <h5>A aplicação de notas rápidas que denominei de "Note Tasks", fiz com o intuito de eliminar notas e papéis autoadesivos.
                                        Optei por uma abordagem rápida e que fosse eficiente ao ponto de com alguns segundos, deixar um registro/lembrete até que este fosse concluído pelo usuário.
                                        <br />
                                        As notas são digitadas e salvas por meio do LocalStorage, procedimento que possibilita ao usuário que suas notas sejam visíveis apenas por si próprio e abertas apenas em seu dispositivo.
                                        Também vale lembrar que as notas são armazenadas até que o usuário marque como concluída, caso contrário, há a possibilidade de fechar o navegador ou até mesmo desligar o dispositivo e as notas continuarem salvas.
                                        <br />
                                        <br />
                                        Para este projeto, utilizei:
                                        <br />
                                        🎯 HTML
                                        <br />
                                        🎯 CSS
                                        <br />
                                        🎯 JavaScript
                                    </h5>
                                    <h4>09 de Fevereiro de 2023</h4>
                                </div>
                            </div>

                            <span className="close-card" onClick={closeCardProject}><IoClose color="black" size={17} /></span>
                        </div>

                    ) : selectedProject === "projeto6" ? (

                        <div className="project-container">

                            <div className="project-left">
                                <div className="video-project">
                                    <iframe src="https://streamable.com/e/ixgw9a?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay"></iframe>
                                </div>
                                <div className="buttons-project">
                                    <a onClick={notPosted} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        LinkedIn
                                    </a>
                                    <a onClick={notDisponible} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        GitHub
                                    </a>
                                    <a onClick={notDisponible} className="btn-proj" target="_blank" rel="noopener noreferrer">
                                        Acessar Projeto
                                    </a>
                                </div>
                            </div>

                            <div className="project-right">
                                <div className="buttons-project">
                                    <h1>Aplicativo G10</h1>
                                    <h5>O aplicativo do G10, foi um aplicativo desenvolvido sob demanda para um grupo de pessoas que se reúnem com o intuito de jogar jogos.
                                        As regras são que os jogos não tem um número de rodadas pré-estabelecidas, jogam 4 pessoas por vez e geralmente perdem duas pessoas, que continuam e as outras duas que ganharam saem para dar lugar aos próximos 2 competidores.
                                        Ao fim de vários ciclos de rodadas de quantidades não definidas pré início, os jogadores que tiverem mais derrotas (denominados lavadores) são os que sofrem uma prenda.
                                        <br />
                                        <br/>
                                        Para atender a esses critérios e regras, utilizei classificação em listas, mapeamento, chaveamento com arrays. Criei rankings e a próxima atualização será integrar em um banco de dados online para que todos os jogadores tenham acesso simultâneo aos resultados e partidas.
                                        <br />
                                        <br />
                                        Para este aplicativo, utilizei:
                                        <br />
                                        🎯 React Native
                                    </h5>
                                    <h4>09 de Julho de 2023</h4>
                                </div>
                            </div>

                            <span className="close-card" onClick={closeCardProject}><IoClose color="black" size={17} /></span>
                        </div>

                    ) : (
                        <></>
                    )}

                </div>

            ) : (
                <></>

            )}
        </section>
    )
}

export default MyProjects;