import React from "react";
import videoBg from '../img/bg.mp4';
import posterBg from '../img/bg.png';
import rotatephoneimg from '../img/rotatephone.png'

const LandscapingMode = () => {

    return (
        <section>
            <div> <video src={videoBg} autoPlay loop muted poster={posterBg} /> </div>

            <div className="alert-landscaping">
                <div className="alert-text">
                    <h1>Para uma melhor experiência, abra esta aplicação num desktop.</h1>
                    <img src={rotatephoneimg} width={160}/>
                    <h2>Caso queira continuar por aqui, gire a tela do seu dispositivo.</h2>
                </div>
            </div>

        </section>
    )
}

export default LandscapingMode;