import React, { useState } from "react";

const SelectedInfoEN = () => {
    const [activeOption, setActiveOption] = useState("Sobre Mim");

    const openTag = "< ";
    const closeTag = " />";

    const handleOptionClick = (option) => {
        setActiveOption(option);
    };

    return (
        <div className="aboutme-container">
            <div className="about-options">
                <span className={activeOption === "Sobre Mim" ? "active-option" : ""}
                    onClick={() => handleOptionClick("Sobre Mim")}><span className="purpleTag">{openTag}</span>About me<span className="purpleTag">{closeTag}</span></span>

                <span className={activeOption === "Objetivos" ? "active-option" : ""}
                    onClick={() => handleOptionClick("Objetivos")}><span className="purpleTag">{openTag}</span>Objectives<span className="purpleTag">{closeTag}</span></span>

                <span className={activeOption === "Experiências" ? "active-option" : ""}
                    onClick={() => handleOptionClick("Experiências")}><span className="purpleTag">{openTag}</span>Experiences<span className="purpleTag">{closeTag}</span></span>

            </div>




            {activeOption === "Sobre Mim" ? (
                <div className="info-description">
                    <h5>I am a versatile and effective professional in my projects. My <span className="destaque-am">creativity</span> and ability to devise solutions always lead me to <span className="destaque-am">innovate</span>. I have an analytical and centered profile, focused and determined. I fell into the world of programming for the simple fact that I wanted to know how things work, or better yet, the <span className="destaque-am">backend</span> of things. I am passionate about learning, knowledge, creativity and <span className="destaque-am">innovation</span>.</h5>
                </div>

            ) : activeOption === "Objetivos" ? (
                <div className="info-description">
                    <h5>My primary objective is to solidify my trajectory in the world of programming, integrating my knowledge with front-end and back-end skills. I aim to <span className="destaque-am">contribute</span> to <span className="destaque-am">innovative</span>projects, aligned with contemporary technological demands. Furthermore, I seek to improve my skills in <span className="destaque-am">cybersecurity</span> and ethical hacking, exploring new professional horizons and constantly<span className="destaque-am">challenging</span>myself.</h5>
                </div>

            ) : (
                activeOption === "Experiências" ? (
                    <div className="info-description">
                        <h5>I have a degree in accounting sciences from the Methodist University of São Paulo and I am studying for a degree in <span className="destaque-am">Software Engineering</span> from the University of Northern Paraná. I work as a Controls and Business Analyst and am self-employed providing services and solutions in software and web applications. I have personal and public projects in my portfolio that prove my <span className="destaque-am">proficiency</span> under development. My journey as <span className="destaque-am">developer</span> Freelancing enabled me to create effective and intuitive digital solutions. I am prepared to consolidate these experiences in a new professional chapter, seeking exciting opportunities in <span className="destaque-am">programming</span> and cybersecurity.</h5>
                    </div>

                ) : (<> </>)
            )}




        </div>
    )
}

export default SelectedInfoEN;