import React, { useState } from "react";

const SelectedInfo = () => {
    const [activeOption, setActiveOption] = useState("Sobre Mim");

    const openTag = "< ";
    const closeTag = " />";

    const handleOptionClick = (option) => {
        setActiveOption(option);
    };

    return (
        <div className="aboutme-container">
            <div className="about-options">
                <span className={activeOption === "Sobre Mim" ? "active-option" : ""}
                    onClick={() => handleOptionClick("Sobre Mim")}><span className="purpleTag">{openTag}</span>Sobre Mim<span className="purpleTag">{closeTag}</span></span>

                <span className={activeOption === "Objetivos" ? "active-option" : ""}
                    onClick={() => handleOptionClick("Objetivos")}><span className="purpleTag">{openTag}</span>Objetivos<span className="purpleTag">{closeTag}</span></span>

                <span className={activeOption === "Experiências" ? "active-option" : ""}
                    onClick={() => handleOptionClick("Experiências")}><span className="purpleTag">{openTag}</span>Experiências<span className="purpleTag">{closeTag}</span></span>

            </div>




            {activeOption === "Sobre Mim" ? (
                <div className="info-description">
                    <h5>Sou um profissional versátil e eficaz nos meus projetos. Minha <span className="destaque-am">criatividade</span> e capacidade de idealizar soluções me levam sempre a <span className="destaque-am">inovar</span>. Possuo um perfil analítico e centrado, focado e determinado. Caí no universo da programação pelo simples fato de almejar saber como as coisas funcionam, ou melhor, o <span className="destaque-am">backend</span> das coisas. Sou um apaixonado pelo saber, pelo conhecimento, pela criatividade e <span className="destaque-am">inovação</span>.</h5>
                </div>

            ) : activeOption === "Objetivos" ? (
                <div className="info-description">
                    <h5>Meu objetivo primordial é solidificar minha trajetória no universo da programação, integrando meu conhecimento com as habilidades do front-end e back-end. Ambiciono <span className="destaque-am">contribuir</span> para projetos <span className="destaque-am">inovadores</span>, alinhados com as demandas tecnológicas contemporâneas. Além disso, busco aprimorar minhas competências em <span className="destaque-am">cybersegurança</span> e hacking ético, explorando novos horizontes profissionais e <span className="destaque-am">desafiando-me</span> constantemente.</h5>
                </div>

            ) : (
                activeOption === "Experiências" ? (
                    <div className="info-description">
                        <h5>Sou formado em ciências contábeis pela Universidade Metodista de São Paulo e estou Graduando em <span className="destaque-am">Engenharia de Software</span> pela Universidade do Norte do Paraná. Atuo como Analista de Controles e Negócios e sou autônomo prestando serviços e soluções em software e aplicações web. Tenho projetos pessoais e públicos no meu portfólio que comprovam minha <span className="destaque-am">proficiência</span> em desenvolvimento. Minha jornada como <span className="destaque-am">desenvolvedor</span> freelancer capacitou-me a criar soluções digitais eficazes e intuitivas. Estou preparado para consolidar essas experiências em um novo capítulo profissional, buscando oportunidades instigantes no cenário da <span className="destaque-am">programação</span> e da segurança cibernética.</h5>
                    </div>

                ) : (<> </>)
            )}




        </div>
    )
}

export default SelectedInfo;