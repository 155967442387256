// LikeButton.js
import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, runTransaction, onValue } from "firebase/database";
import { AiOutlineLike } from "react-icons/ai";

const firebaseConfig = {
    apiKey: "AIzaSyDg7QnnITdXtTqgHnmRiRYg8VgLrRqnk7A",
    authDomain: "likes-counter-781ba.firebaseapp.com",
    projectId: "likes-counter-781ba",
    storageBucket: "likes-counter-781ba.appspot.com",
    messagingSenderId: "337977279825",
    appId: "1:337977279825:web:411363b34d1fec6ea3bf70",
    measurementId: "G-5P414LQPL4"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

const LikeButton = () => {
    const [likes, setLikes] = useState(null);

    useEffect(() => {
        const likesRef = ref(database, "likes");
        onValue(likesRef, (snapshot) => {
            const likesCount = snapshot.val() || 0;
            setLikes(likesCount);
        });
    }, [database]);

    const handleLike = () => {
        const likesRef = ref(database, "likes");

        runTransaction(likesRef, (currentLikes) => {
            return (currentLikes || 0) + 1;
        }).then(() => {

            import("firebase/analytics").then(({ logEvent }) => {
                logEvent(analytics, "like_button_click", {
                    likes_before_click: likes,
                    likes_after_click: likes + 1,
                });
            });
        });
    };

    if (likes === null) {
        return <h3>...</h3>;
    }

    return (
        <div className="like-container">
            <div>
                <span><AiOutlineLike onClick={handleLike} /></span>
            </div>
            <div>
                <h3>{likes}</h3>
            </div>
        </div>
    );
};

export default LikeButton;
