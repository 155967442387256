import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '../src/css/home.css';
import '../src/css/menu.css';
import '../src/css/skills.css';
import '../src/css/recommendations.css';
import '../src/css/contactme.css';
import '../src/css/aboutme.css';
import '../src/css/myprojects.css';
import '../src/css/loading.css';
import '../src/css/backtohome.css';
import '../src/css/components.css';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);